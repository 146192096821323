<template>
  <div>
    <b-form ref="form-business-public" @submit="onSubmit" @reset="onReset" v-if="show" autocomplete="off">
      <b-card title="COVID-19 Message">
        <b-card-body>
          <b-form-group
            id="formgroup-C19"
            label-for="checkbox-C19"
          >
            <b-form-checkbox
              id="checkbox-C19"
              v-model="messageC19"
              name="checkbox-C19"
              value="1"
              unchecked-value="0"
            >
              Display the COVID-19 message on my site.
            </b-form-checkbox>
          </b-form-group>
        </b-card-body>
      </b-card>

      <b-card title="Public Contact" sub-title="Add publically visible contact methods.">
        <b-card-body>
          <b-form-row>
            <b-col cols="12" md="6">
              <b-form-group id="formgroup-business-phone"
                            label="Phone:"
                            label-for="formfield-business-phone">
                <masked-input 
                  id="formfield-business-phone" 
                  class="form-control"
                  v-model="businessForm.phone" 
                  :mask="['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]"
                  placeholderChar="#"
                  placeholder="Business Phone"
                  required
                ></masked-input>
              </b-form-group>
            </b-col>
            <b-col cols="12" md="6">
              <b-form-group id="formgroup-business-email"
                            label="Email:"
                            label-for="formfield-business-email">
                <b-input id="formfield-business-email" v-model="businessForm.email" placeholder="Business Email" required></b-input>
              </b-form-group>
            </b-col>
          </b-form-row>
        </b-card-body>
      </b-card>

      <b-card title="Mailing Address" sub-title="Mailing address for this business.">
        <b-card-body>
          <b-form-row>
              <b-col>
                <b-form-group id="formgroup-business-address1"
                              label="Address:"
                              label-for="formfield-business-address1">
                  <b-form-input id="formfield-business-address1"
                                type="text"
                                v-model="businessForm.address1"
                                placeholder="123 fourth st">
                  </b-form-input>
                </b-form-group>
              </b-col>
              <b-col cols="3">
                <b-form-group id="formgroup-business-address2"
                              label="Apt/Suite/PO Box:"
                              label-for="formfield-business-address2">
                  <b-form-input id="formfield-business-address2"
                                type="text"
                                v-model="businessForm.address2">
                  </b-form-input>
                </b-form-group>
              </b-col>
            </b-form-row>
            <b-form-row>
              <b-col md="4" cols="12">
                <b-form-group id="formgroup-business-city"
                              label="City:"
                              label-for="formfield-business-city">
                  <b-form-input id="formfield-business-city"
                                type="text"
                                v-model="businessForm.city">
                  </b-form-input>
                </b-form-group>
              </b-col>
              <b-col md="4" cols="8">
                <b-form-group id="formgroup-business-state"
                              label="State:"
                              label-for="formfield-business-state">
                  <b-form-select id="formfield-business-state"
                                :options="states"
                                v-model="businessForm.state">
                  </b-form-select>
                </b-form-group>
              </b-col>
              <b-col md="4" cols="4">
                <b-form-group id="formgroup-business-zipCode"
                              label="Zip:"
                              label-for="formfield-business-zipCode">
                  <b-form-input id="formfield-business-zipCode"
                                type="number"
                                v-model="businessForm.zipCode"
                                required>
                  </b-form-input>
                </b-form-group>
              </b-col>
            </b-form-row>
        </b-card-body>
      </b-card>

      <!-- social media -->
      <b-card title="Social Media" sub-title="Add applicable social media URLs below.">
        <b-card-body>
          <b-form-group v-for="(media, key, index) in socialOptions"
            :key="index"
          >
            <slot name="label"><i :class="'nav-icon icon-social-' + media.label.toLowerCase()"></i> {{ media.label }}</slot>
            <b-input v-model="social[key]" :placeholder="media.placeholder"></b-input>
          </b-form-group>
        </b-card-body>
      </b-card>
      
      <!-- sports -->
      <b-card title="Sports Offered" sub-title="Select all that apply.">
        <b-card-body>
          <b-form-group>
            <b-form-checkbox-group
              id="formgroup-business-sports"
              v-model="businessForm.sports"
              :options="sportsOptions"
              name="sports"
              value-field="@id"
              text-field="label"
            ></b-form-checkbox-group>
          </b-form-group>
        </b-card-body>
      </b-card>
      
      <!-- payment options -->
      <b-card title="Payment Options">
        <b-card-body>
          <b-form-group v-for="(option, key, index) in paymentOptions"
            :label="option.label"
            label-class="font-weight-bold pt-0"
            :key="index"
          >
            <b-form-group
              label-cols-sm="3"
              label-align-sm="right"
              :label-for="'payment-' + option.id + '-businessPaymentOptionId'"
            >
              <b-form-checkbox
                :id="'payment-' + option.id + '-active'"
                v-model="businessPaymentOptions[option.id].active"
                name="'payment-' + option.id + '-active'"
              >
                Enable this option
              </b-form-checkbox>
            </b-form-group>
            <template v-if="businessPaymentOptions[option.id].active">
              <b-form-group
                v-for="(field, indexField) in option.fields"
                label-cols-sm="3"
                :label="field.label"
                label-align-sm="right"
                :label-for="'payment-' + option.id + '-' + field.name"
                :key="indexField"
              >
                <!-- textarea fields -->
                <b-form-textarea v-if="field.type && field.type === 'textarea'"
                  :id="'payment-' + option.id + '-' + field.name"
                  v-model="businessPaymentOptions[option.id][(field.private?'paymentOptionDataPrivate':'paymentOptionData')][field.name]"
                  placeholder=""
                  rows="4"
                  max-rows="6"
                ></b-form-textarea>

                <!-- text fields -->
                <b-input-group v-else-if="field.private">
                  <b-form-input
                    type="password" 
                    :id="'payment-' + option.id + '-' + field.name" 
                    v-model="businessPaymentOptions[option.id][(field.private?'paymentOptionDataPrivate':'paymentOptionData')][field.name]"
                  ></b-form-input>
                  <b-input-group-append>
                    <b-button @click="switchPassVisibility('payment-' + option.id + '-' + field.name)"><i class="fa fa-eye"></i></b-button>
                  </b-input-group-append>
                </b-input-group>

                <!-- text fields -->
                <b-form-input v-else
                  type="text" 
                  :id="'payment-' + option.id + '-' + field.name" 
                  v-model="businessPaymentOptions[option.id][(field.private?'paymentOptionDataPrivate':'paymentOptionData')][field.name]"
                ></b-form-input>

              </b-form-group>
            </template>
          </b-form-group>
          <!-- service tax amount -->
          <b-form-group 
            label="Service Tax"
            label-class="font-weight-bold pt-0"
          >
            <b-form-group
              label-cols-sm="3"
              label-align-sm="right"
              label-for="payment-sales-tax"

            >
              <p>Use this section to add tax to your client's invoices. The amount listed here will be added to each new invoice.</p>
            </b-form-group>
            <b-form-group
              label-cols-sm="3"
              label-align-sm="right"
              label-for="payment-service-tax"
            >
              <b-input-group style="width:295px">
                <b-input-group-prepend is-text>
                  <b-form-checkbox
                    id="payment-service-tax"
                    v-model="serviceTaxExists"
                    class="mr-n2"
                    :value="true"
                  ><span v-if="!serviceTaxExists" class="mr-2">My state collects tax on services rendered.</span></b-form-checkbox>
                </b-input-group-prepend>
                <b-form-input
                  v-if="serviceTaxExists"
                  type="number"
                  step="0.0001"
                  min="0.0000"
                  max="1"
                  size="6"
                  class="w-auto"
                  v-model="serviceTaxAmount"
                  placeholder="Decimal format (e.g., 0.05 for 5%)"
                  id="payment-service-tax-amount" 
                  aria-label="Service Tax Amount"
                ></b-form-input>
              </b-input-group>
            </b-form-group>
          </b-form-group>
        </b-card-body>
      </b-card>
      
      <b-card title="Multi-Player Discount" sub-title="Enable or disable the Multi-Player Discount for your business (enabled by default).">
        <b-card-body>
          <p>
            When enabled, a 10% Multi-Player Discount will automatically be applied to the total cost 
            of an invoice with multiple registrations. If a discount code is used, then the greater discount will be applied.
          </p>
          <b-form-group
            id="formgroup-mpd"
            label-for="checkbox-mpd"
          >
            <b-form-checkbox
              id="checkbox-mpd"
              v-model="businessForm.autoMultiPlayerDiscount"
              name="checkbox-mpd"
              :value="true"
              :unchecked-value="false"
            >
              Enable Multi-Player Discount
            </b-form-checkbox>
          </b-form-group>
        </b-card-body>
      </b-card>
      <b-card title="Other Settings">
        <b-card-body>
          <BusinessSettings />
        </b-card-body>
      </b-card>
      
      <div class="clearfix mb-4">
        <b-button type="reset" variant="danger">Reset</b-button>
        <b-button type="submit" variant="success" class="float-right">Submit</b-button>
      </div>
    </b-form>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import MaskedInput from 'vue-text-mask'
import { BFormGroup } from 'bootstrap-vue'
import BusinessSettings from './BusinessSettings.vue'

const MESSAGE_C19_DEFAULT = 1 // covid19 message

const formDefaults = {
  phone: '',
  email: '',
  address1: '',
  address2: '',
  city: '',
  state: '',
  zipCode: '',
  social: {
    instagram: '',
    twitter: '',
    facebook: ''
  },
  sports: [],
  autoMultiPlayerDiscount: 1
}
export default {
  components: {
    MaskedInput,
    BFormGroup,
    BusinessSettings
  },
  props: {
    bid: {
      default () {
        return null
      }
    }
  },
  data () {
    return {
      show: true,
      businessId: null,
      socialOptions: {
        instagram: {
          label: 'Instagram',
          placeholder: 'example: https://www.instagram.com/company/'
        },
        twitter: {
          label: 'Twitter',
          placeholder: 'example: https://twitter.com/@company/'
        },
        facebook: {
          label: 'Facebook',
          placeholder: 'example: https://www.facebook.com/page/'
        },
        yelp: {
          label: 'Yelp',
          placeholder: 'example: https://www.yelp.com/biz/company'
        },
        tiktok: {
          label: 'TikTok',
          placeholder: 'example: https://www.tiktok.com/@user'
        }
      },
      social: {
        instagram: '',
        twitter: '',
        facebook: '',
        yelp: '',
        tiktok: ''
      },
      businessForm: {
        phone: '',
        email: '',
        address1: '',
        address2: '',
        city: '',
        state: '',
        zipCode: '',
        social: [],
        sports: [],
        autoMultiPlayerDiscount: 1
      },
      paymentOptions: [],
      businessPaymentOptions: {},
      serviceTaxExists: false,
      serviceTaxAmount: null,

      // covid19 message
      messageC19: null,
      messageC19Custom: null
    }
  },
  computed: {
    states () {
      return this.$store.getters.states
    },
    sportsOptions () {
      return this.$store.getters.sports
    }
  },
  methods: {
    ...mapActions([
      'getBusiness',
      'putBusiness',
      'getSports',
      'resetSports',
      'getPaymentOptions',
      'getBusinessPaymentOptions',
      'postBusinessPaymentOptions',
      'putBusinessPaymentOptions',
      'resetBusinessPaymentOptions',
      // for covid19 message
      'getContent',
      'postContent',
      'putContent',
      'resetContent'
    ]),
    setSocialMedia () {
      // set social media
      for (let i in this.businessForm.social) {
        if (this.businessForm.social[i].hasOwnProperty('provider')) {
          this.social[this.businessForm.social[i].provider] = this.businessForm.social[i].url
        }
      }
    },
    async loadBusiness () {
      if (!this.currentBusiness.id) {
        await this.getBusiness({
          id: this.bid
        })
      }
      this.businessForm = { ...this.currentBusiness }
      this.setSocialMedia()
      // set payment option values
      this.doGetBusinessPaymentOptions()
      await this.getContent({ // @XXX temp resolve to update covid19 message
        business: this.businessId,
        type: 'covid19'
      }).then((resp) => {
        if (this.$store.getters.content.length) {
          this.messageC19Custom = this.$store.getters.content[0].id
          this.messageC19 = parseInt(this.$store.getters.content[0].summary)
        } else {
          this.messageC19 = MESSAGE_C19_DEFAULT
        }
      })
      this.serviceTaxAmount = this.currentBusiness.serviceTaxAmount
    },
    async doGetCorpPaymentOptions () {
      return await this.getPaymentOptions({
        active: true
      }).then(corp => {
        for (let i in corp) {
          if (!this.businessPaymentOptions[corp[i].id]) {
            this.$set(this.businessPaymentOptions, corp[i].id, {})
            this.$set(this.businessPaymentOptions[corp[i].id], 'id', null)
            this.$set(this.businessPaymentOptions[corp[i].id], 'active', false)
            this.$set(this.businessPaymentOptions[corp[i].id], 'messageIni', '')
            this.$set(this.businessPaymentOptions[corp[i].id], 'messageComplete', '')
            this.$set(this.businessPaymentOptions[corp[i].id], 'paymentOptionData', {})
            this.$set(this.businessPaymentOptions[corp[i].id], 'paymentOptionDataPrivate', {})
          }
          for (let i2 in corp[i].fields) {
            this.$set(this.businessPaymentOptions[corp[i].id][(corp[i].fields[i2].private?'paymentOptionDataPrivate':'paymentOptionData')], 
            corp[i].fields[i2].name, '')
          }
        }
        this.paymentOptions = corp
      })
    },
    doGetBusinessPaymentOptions () {
      if (!this.businessId) {
        return
      }
      this.getBusinessPaymentOptions({
        business: this.businessId
      }).then(options => {
        for (let i in options) {
          let paymentOptionId = options[i].paymentOption.id

          this.businessPaymentOptions[paymentOptionId].id = options[i].id
          this.businessPaymentOptions[paymentOptionId].active = options[i].active

          for (let key in options[i].paymentOptionData) {
            this.businessPaymentOptions[paymentOptionId].paymentOptionData[key] = options[i].paymentOptionData[key]
          }

          for (let key in options[i].paymentOptionDataPrivate) {
            this.businessPaymentOptions[paymentOptionId].paymentOptionDataPrivate[key] = options[i].paymentOptionDataPrivate[key]
          }
        }
      })
    },
    switchPassVisibility (id) {
      let passwordField = document.querySelector('#' + id)
      if (passwordField.getAttribute('type') === 'password') passwordField.setAttribute('type', 'text')
      else passwordField.setAttribute('type', 'password')
    },
    async onSubmit (e) {
      e.preventDefault()
      // set social
      this.businessForm.social = []
      for (let k in this.social) {
        this.businessForm.social.push({
          provider: k,
          url: this.social[k]
        })
      }
      // update business data
      await this.putBusiness({
        id: this.bid,
        phone: this.businessForm.phone,
        email: this.businessForm.email,
        address1: this.businessForm.address1,
        address2: this.businessForm.address2,
        city: this.businessForm.city,
        state: this.businessForm.state,
        zipCode: this.businessForm.zipCode,
        social: this.businessForm.social,
        sports: this.businessForm.sports,
        serviceTaxAmount: parseFloat(this.serviceTaxAmount),
        autoMultiPlayerDiscount: this.businessForm.autoMultiPlayerDiscount
      })

      for (let optId in this.businessPaymentOptions) {
        let option = this.businessPaymentOptions[optId]
        if (option.active && !option.id) {
          // add new
          delete option.id
          option.business = this.businessForm['@id']
          option.paymentOption = '/api/payment_options/' + optId
          await this.postBusinessPaymentOptions(option)
        } else if (option.id) {
          // update
          await this.putBusinessPaymentOptions(option)
        }
      }

      // update covid19 message option
      if (!this.messageC19Custom) {
        await this.postContent({
          business: this.currentBusiness['@id'],
          dateCreated: this.$moment().format(),
          dateStart: this.$moment().format(),
          type: 'covid19',
          title: 'Show COVID-19 Message',
          slug: 'show-message',
          summary: this.messageC19,
          content: 'Show COVID-19 Message',
          author: this.currentUser['@id']
        })
      } else {
        await this.putContent({
          id: this.messageC19Custom,
          summary: this.messageC19 + ''
        })
      }

      this.showSuccess({title: 'Saved!', message: 'The public settings have been saved.'})
    },
    onReset () {
      this.businessForm = {...formDefaults}
      if (this.businessId !== null) {
        this.loadBusiness()
      }
    }
  },
  watch: {
    bid (val) {
      this.businessId = val
      this.loadBusiness()
      // this.doGetBusinessPaymentOptions()
    },
    serviceTaxExists (val) {
      if (!val) {
        this.serviceTaxAmount = null
      }
    },
    serviceTaxAmount (val) {
      if (val) {
        this.serviceTaxExists = true
      }
    }
  },
  async mounted () {
    this.businessId = this.bid
    this.businessForm = {...formDefaults}
    // set available sport options
    await this.getSports()
    // set available payment options
    await this.doGetCorpPaymentOptions()
    // load business data
    if (this.businessId !== null) {
      this.loadBusiness()
    }
  },
  destroyed () {
    this.resetSports()
    this.resetBusinessPaymentOptions()
    this.resetContent()// @XXX temp resolve to update covid19 message
  }
}
</script>
