<template>
    <div v-if="Object.keys(settings).length">
        <div v-for="setting in businessSettingOptions" :key="setting.id" class="mb-3">
            <div>
              <b>{{ setting.title }}</b> <BFormRadioGroup
                class="d-inline-block ml-2"
                v-if="setting.valueType === 'bool'"
                v-model="settings[setting.name].value"
                :options="[{ text: 'Yes', value: '1' }, { text: 'No', value: '0' }]"
                @change="saveBusinessSetting(setting.name)"
              />
              <input v-else v-model="settings[setting.name].value" @change="saveBusinessSetting(setting.name)" />
            </div>
            <div class="mb-2">{{ setting.description }}</div>
            
        </div>
    </div>
</template>

<script>
import { mapActions } from 'vuex'
export default {
  name: 'BusinessSettings',
  data () {
    return {
      settings: {}
    }
  },
  computed: {
    businessSettingOptions () {
      return this.$store.getters.businessSettingOptions
    },
    businessSettings () {
      return this.$store.getters.businessSettings
    }
  },
  methods: {
    ...mapActions([
      'getBusinessSettingOptions',
      'getBusinessSettings',
      'postBusinessSetting',
      'putBusinessSetting'
    ]),
    saveBusinessSetting (settingName) {
      const setting = this.settings[settingName]
      setting.setting = setting.setting['@id']
      if (setting.id) {
        this.putBusinessSetting(setting)
      } else {
        this.postBusinessSetting({
          business: this.currentBusiness['@id'],
          setting: setting.setting,
          value: setting.value
        })
      }
    }
  },
  async mounted () {
    await this.getBusinessSettingOptions()
    await this.getBusinessSettings({
      business: this.currentBusiness.id
    }).then(resp => {
      for (const option of this.businessSettingOptions) {
        const businessSetting = resp.find(s => s.setting.id === option.id)
        if (businessSetting) {
          // set from current business setting
          this.$set(this.settings, option.name, businessSetting)
        } else {
          // set default value
          this.$set(this.settings, option.name, {
            setting: option,
            value: option.valueDefault
          })
        }
      }
    })
  }
}
</script>
